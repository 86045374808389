import { Injectable } from '@angular/core';
import { OptionService } from './option.service';
import { AffiliationDetailServiceProxy, AffiliationGroupServiceProxy} from '@shared/service-proxies/service-proxies';

@Injectable()
export class AffiliationService {
    constructor(
        private _affiliationDetailService: AffiliationDetailServiceProxy,
        private _affiliationGroupService: AffiliationGroupServiceProxy,
        private _optionService: OptionService,
    ) {
    }

    getDetailOptions() {
        return new Promise(resolve => {
            this._affiliationDetailService.getCurrentUserAffiliationGroupDetailNames().subscribe(result => {
                let names = result;
                resolve(this._optionService.converStringListToOptions(names));
            });
        });
    }

    getDetailOptionsByGroupName(groupName) {
        return new Promise(resolve => {
            this._affiliationDetailService.getAffiliationDetailNamesByGroupName(groupName).subscribe(result => {
                let names = result;
                resolve(this._optionService.converStringListToOptions(names));
            });
        });
    }

    getGroupOptions() {
        return new Promise(resolve => {
            this._affiliationGroupService.getAffiliationGroupNames().subscribe(result => {
                let names = result;
                resolve(this._optionService.converStringListToOptions(names));
            });
        });
    }

    getCurrentUserAffiliationDetailName() {
        return new Promise(resolve => {
            this._affiliationDetailService.getCurrentUserAffiliationDetail().subscribe(result => {
                let names = result.name;
                resolve(names);
            });
        });
    }
}
