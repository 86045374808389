import { Component, Injector, ViewEncapsulation } from '@angular/core';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import * as _ from 'lodash';
import { AppComponentBase } from 'shared/common/app-component-base';

@Component({
    selector: 'vehicle-type-preference',
    templateUrl: './vehicle-type-preference.component.html',
    encapsulation: ViewEncapsulation.None,
    animations: [appModuleAnimation()]
})
export class VehicleTypePreferenceComponent extends AppComponentBase {
    new: boolean = false;
    used: boolean = false;
    //demo: boolean = false;
    constructor(injector: Injector) {
        super(injector);
    }

    clickType($event: string): void {
        switch ($event) {
            case 'new':
                this.new = !this.new;
                break;
            case 'used':
                this.used = !this.used;
                break;
        }
    }
    setVehiclePreferences(preferences: string[]) {
        this.reset();

        for (var preference of preferences) {
            this.clickType(preference.toLowerCase());
        }
    }
    getVehiclePreferences(): string {
        let result = "";
        if (this.new) {
            result = "New";
        }
        if (this.used) {
            if (result != "") {
                result += ",";
            }
            result += "Used";

        }
        return result;
    }
    reset() {
        this.new = false;
        this.used= false;
    }
}
