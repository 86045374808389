import { Component, Injector, ViewEncapsulation, Input, Output, EventEmitter } from '@angular/core';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import * as _ from 'lodash';
import { AppComponentBase } from 'shared/common/app-component-base';
import { ICheckOptionDictionary } from './../check-option.model';

@Component({
    selector: 'radio-common',
    templateUrl: './radio-common.component.html',
    encapsulation: ViewEncapsulation.None,
    animations: [appModuleAnimation()]
})
export class RadioCommonComponent extends AppComponentBase {
    @Input() checkOptionDictionary: ICheckOptionDictionary = {};
    @Output() selectedOptionChange: EventEmitter<string> = new EventEmitter<string>();

    @Input() title: string = '';

    currentSelectedOption: string = undefined;
    constructor(injector: Injector) {
        super(injector);
    }

    keys(): Array<string> {
        return Object.keys(this.checkOptionDictionary);
    }

    onSelectChange(selected): void {
        if (this.currentSelectedOption != undefined) {
            this.checkOptionDictionary[this.currentSelectedOption].isSelected =
                !this.checkOptionDictionary[this.currentSelectedOption].isSelected;
        }
        this.checkOptionDictionary[selected].isSelected =
            !this.checkOptionDictionary[selected].isSelected;

        this.currentSelectedOption = selected;

        this.selectedOptionChange.emit(selected);
    }
}
