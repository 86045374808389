import { Injector, OnInit, AfterViewInit, ViewChild} from '@angular/core';
import { Location } from '@angular/common';
import { AppAuthService } from '@app/shared/common/auth/app-auth.service';
import { AppComponentBase } from '@shared/common/app-component-base';
import { AppSessionService } from '@shared/common/session/app-session.service';

export abstract class WseComponentBase extends AppComponentBase implements OnInit {
    userName: string = '';
    currentYear: number;
    authService: AppAuthService;
    location: Location;
    appSession: AppSessionService;
    constructor(
        injector: Injector
    ) {
        super(injector);
        this.authService = injector.get(AppAuthService);
        this.location = injector.get(Location);
        this.appSession = injector.get(AppSessionService);

        this.currentYear = new Date().getFullYear();
        if (!this.appSession.user == false) {
            this.userName = this.appSession.user.name + ' ' + this.appSession.user.surname;
        } else {
            this.userName = "Guest";
        }
    }

    ngOnInit(): void {
    }

    logout(): void {
        sessionStorage.removeItem("covidModalLoaded");
        this.authService.logout();

    }
    goBack(): void {
        this.location.back();
    }

    toTime(timeString): Date {
        var timeTokens = timeString.split(':');
        return new Date(1970, 0, 1, timeTokens[0], timeTokens[1], timeTokens[2]);
    }
}
