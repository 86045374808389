import { Component, Injector, ChangeDetectorRef, ViewEncapsulation } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { AppAuthService } from '@app/shared/common/auth/app-auth.service';

@Component({
    templateUrl: './wse-header.component.html',
    styleUrls: ['./wse-header.component.less'],
    selector: 'wse-header',
    encapsulation: ViewEncapsulation.None,
    animations: [appModuleAnimation()]
})
export class WseHeaderComponent extends AppComponentBase {

    constructor(
        injector: Injector,
        private changeDetectorRef: ChangeDetectorRef,
        private _authService: AppAuthService,

        ) {
        super(injector);
    }

    logout(): void {
        this._authService.logout();
    }
}
