import { Injectable } from '@angular/core';
import { OptionService } from './option.service';
import { DealershipServiceProxy } from '@shared/service-proxies/service-proxies';

@Injectable()
export class DealershipService {
    constructor(
        private _dealershipServiceProxy: DealershipServiceProxy,
        private _optionService: OptionService,
    ) {
    }

    getOptions() {
        return new Promise(resolve => {
            this._dealershipServiceProxy.getDealershipNames().subscribe(result => {
                let dealershipNames = result;
                resolve(this._optionService.converStringListToOptions(dealershipNames));
            });
        });
    }
}
