import { Component,  ElementRef, ViewChild, Injector, Input, Output, EventEmitter } from '@angular/core';
import { Option } from './../option.model';

import { AppComponentBase } from '@shared/common/app-component-base';
//declare var $: any;

@Component({
    selector: 'common-combo-box',
    styleUrls: ['./common-combo-box.component.less'],
    templateUrl: './common-combo-box.component.html',
})
export class CommonComboBoxComponent extends AppComponentBase  {
    @Input() options: Option[] = [];
    @Input() title: string = '-- Please Select --';
    @Input() selectedOption: string = undefined;
    @Output() selectedOptionChange: EventEmitter<string> = new EventEmitter<string>();

    @ViewChild('CommonComboBox', { static: true }) comboBoxElement: ElementRef;

    constructor(
        injector: Injector) {
        super(injector);
    }

    selectOption(option: any): void {
        this.selectedOption = option;
        this.selectedOptionChange.emit(option);
    }

    refresh(): void {
    }
}
