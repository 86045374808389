import { Injectable } from '@angular/core';
import { OptionService } from './option.service';
import { VehicleBrandServiceProxy } from '@shared/service-proxies/service-proxies';

@Injectable()
export class VehicleBrandService {
    constructor(
        private _vehicleBrandServiceProxy: VehicleBrandServiceProxy,
        private _optionService: OptionService,
    ) {
    }

    getOptions() {
        return new Promise(resolve => {
            this._vehicleBrandServiceProxy.getVehicleBrandNames().subscribe(result => {
                let brandNames = result;
                resolve(this._optionService.converStringListToOptions(brandNames));
            });
        });
    }
}
