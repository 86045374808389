import { Injectable } from '@angular/core';
import { ConnectGivexCardServiceProxy } from '@shared/service-proxies/service-proxies';
import { AppSessionService } from '@shared/common/session/app-session.service';

@Injectable()
export class HugPointsService {
    constructor(
        private _givexCardServiceProxy: ConnectGivexCardServiceProxy,
        private appSession: AppSessionService
    ) {
    }

    getHugPoint() {
        return new Promise(resolve => {
            if (!this.appSession.user.cardNumber == false) {
                this._givexCardServiceProxy.getBalanceByCardNumber(this.appSession.user.cardNumber).subscribe(
                    reponse => {
                        if (reponse.results[1] == '0' && !reponse.results[2] == false
                        ) { //reponse.results[1] =='0' means the card number is valid
                            resolve(reponse.results[2].split('.')[0]);
                        }
                    });
            } else {
                resolve("0");
            }
        });
    }
}
