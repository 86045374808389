import { Injectable } from '@angular/core';
import { Option } from './option.model';

@Injectable()
export class OptionService {
    converStringListToOptions(inputs: string[]): Array<Option> {
        let options: Option[] = new Array<Option>();
        for (var value of inputs) {
            var option = new Option(value, value);
            options.push(option);
        }
        return options;
    }
}
