import { AbpModule } from '@abp/abp.module';
import * as ngCommon from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { AppUrlService } from './nav/app-url.service';
import { AppUiCustomizationService } from './ui/app-ui-customization.service';
import { AppSessionService } from './session/app-session.service';
import { CookieConsentService } from './session/cookie-consent.service';

import { CommonComboBoxComponent } from './hug-base/common-combo-box/common-combo-box.component';
import { RadioCommonComponent } from './hug-base/radio-common/radio-common.component';
import { MultiCheckBoxComponent } from './hug-base/multi-check-box/multi-check-box.component';

import { FormsModule } from '@angular/forms';
import { ContactPreferenceComponent } from './hug-base/contact-preference/contact-preference.component';
import { VehicleTypePreferenceComponent } from './hug-base/vehicle-type-preference/vehicle-type-preference.component';
import { OptionService } from './hug-base/option.service';
import { DealershipService } from './hug-base/dealership.service';
import { HugPointsService } from './hug-base/hug-points.service';
import { VehicleBrandService } from './hug-base/vehicle-brand.service';
import { CharityService } from './hug-base/charity.service';
import { AffiliationService } from './hug-base/affiliation.service';

@NgModule({
    imports: [
        ngCommon.CommonModule,
        AbpModule,
        FormsModule
    ],
    declarations: [
        CommonComboBoxComponent,
        RadioCommonComponent,
        MultiCheckBoxComponent,
        ContactPreferenceComponent,
        VehicleTypePreferenceComponent,
    ],
    exports: [
        CommonComboBoxComponent,
        RadioCommonComponent,
        MultiCheckBoxComponent,
        ContactPreferenceComponent,
        VehicleTypePreferenceComponent
    ],
})
export class WseCommonModule {
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: WseCommonModule,
            providers: [
                AppUiCustomizationService,
                CookieConsentService,
                AppSessionService,
                AppUrlService,
                OptionService,
                CharityService,
                DealershipService,
                VehicleBrandService,
                HugPointsService,
                AffiliationService,
            ]
        };
    }
}
