import { Component, Injector, ViewEncapsulation, Input, Output, EventEmitter } from '@angular/core';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import * as _ from 'lodash';
import { AppComponentBase } from 'shared/common/app-component-base';

@Component({
    selector: 'contact-preference',
    templateUrl: './contact-preference.component.html',
    encapsulation: ViewEncapsulation.None,
    animations: [appModuleAnimation()]
})
export class ContactPreferenceComponent extends AppComponentBase {
    @Input() selectedContactPreferecne: string = 'call';
    @Output() selectedContactPreferecneChange: EventEmitter<string> = new EventEmitter<string>();

    call: boolean = true;
    email: boolean = false;
    text: boolean = false;
    constructor(injector: Injector) {
        super(injector);
    }

    contactBy($event: string): void {
        this.call = false;
        this.email = false;
        this.text = false;
        switch ($event.toLowerCase()) {
            case 'call':
                this.call = true;
                break;
            case 'email':
                this.email = true;
                break;
            case 'text':
                this.text = true;
                break;
        }

        this.selectedContactPreferecne = $event;
        this.selectedContactPreferecneChange.emit($event);
    }

    getContactPreference(): string {
        return this.call
            ? "Call"
            : this.email
            ? "Email"
            : this.text
            ? "Text"
            : "";
    }
}
