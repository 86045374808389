import { AbpHttpInterceptor, RefreshTokenService } from '@abp/abpHttpInterceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import * as ApiServiceProxies from './service-proxies';
import { ZeroRefreshTokenService } from '@account/auth/zero-refresh-token.service';

@NgModule({
    providers: [
        ApiServiceProxies.AuditLogServiceProxy,
        ApiServiceProxies.CachingServiceProxy,
        ApiServiceProxies.ChatServiceProxy,
        ApiServiceProxies.CommonLookupServiceProxy,
        ApiServiceProxies.EditionServiceProxy,
        ApiServiceProxies.FriendshipServiceProxy,
        ApiServiceProxies.HostSettingsServiceProxy,
        ApiServiceProxies.InstallServiceProxy,
        ApiServiceProxies.LanguageServiceProxy,
        ApiServiceProxies.NotificationServiceProxy,
        ApiServiceProxies.OrganizationUnitServiceProxy,
        ApiServiceProxies.PermissionServiceProxy,
        ApiServiceProxies.ProfileServiceProxy,
        ApiServiceProxies.RoleServiceProxy,
        ApiServiceProxies.SessionServiceProxy,
        ApiServiceProxies.TenantServiceProxy,
        ApiServiceProxies.TenantDashboardServiceProxy,
        ApiServiceProxies.TenantSettingsServiceProxy,
        ApiServiceProxies.TimingServiceProxy,
        ApiServiceProxies.UserServiceProxy,
        ApiServiceProxies.UserLinkServiceProxy,
        ApiServiceProxies.UserLoginServiceProxy,
        ApiServiceProxies.WebLogServiceProxy,
        ApiServiceProxies.AccountServiceProxy,
        ApiServiceProxies.TokenAuthServiceProxy,
        ApiServiceProxies.TenantRegistrationServiceProxy,
        ApiServiceProxies.HostDashboardServiceProxy,
        ApiServiceProxies.PaymentServiceProxy,
        ApiServiceProxies.DemoUiComponentsServiceProxy,
        ApiServiceProxies.InvoiceServiceProxy,
        ApiServiceProxies.SubscriptionServiceProxy,
        ApiServiceProxies.InstallServiceProxy,
        ApiServiceProxies.UiCustomizationSettingsServiceProxy,
        ApiServiceProxies.PayPalPaymentServiceProxy,
        ApiServiceProxies.StripePaymentServiceProxy,
        ApiServiceProxies.DashboardCustomizationServiceProxy,

        ApiServiceProxies.PerformanceReviewServiceProxy,
        ApiServiceProxies.StartPerformanceReviewServiceProxy,
        ApiServiceProxies.SessionServiceProxy,
        ApiServiceProxies.TenantServiceProxy,
        ApiServiceProxies.TenantDashboardServiceProxy,
        ApiServiceProxies.TenantSettingsServiceProxy,
        ApiServiceProxies.TimingServiceProxy,
        ApiServiceProxies.UserServiceProxy,
        ApiServiceProxies.UserLinkServiceProxy,
        ApiServiceProxies.UserLoginServiceProxy,
        ApiServiceProxies.WSEUserServiceProxy,
        ApiServiceProxies.ContactUsServiceProxy,
        ApiServiceProxies.DealershipComboBoxServiceProxy,
        ApiServiceProxies.DealershipEmployeeServiceProxy,
        ApiServiceProxies.AbpUserServiceProxy,
        ApiServiceProxies.ConnectMenuServiceProxy,
        ApiServiceProxies.ConnectPostServiceProxy,
        ApiServiceProxies.ConnectContentServiceProxy,
        ApiServiceProxies.ConnectMenuComboBoxServiceProxy,
        ApiServiceProxies.EmergencyContactRelationshipComboBoxServiceProxy,
        ApiServiceProxies.EmergencyContactRelationshipServiceProxy,
        ApiServiceProxies.WorkScheduleExceptionReasonDetailComboBoxServiceProxy,
        ApiServiceProxies.WorkScheduleExceptionReasonGroupComboBoxServiceProxy,
        ApiServiceProxies.WorkScheduleExceptionRequestServiceProxy,
        ApiServiceProxies.WorkScheduleExceptionReasonDetailServiceProxy,
        ApiServiceProxies.VacationPayDateComboBoxServiceProxy,
        ApiServiceProxies.VacationPayDateServiceProxy,
        ApiServiceProxies.ClarityEmployeeServiceProxy,
        ApiServiceProxies.WorkScheduleExceptionRequestNotificationTaskServiceProxy,
        ApiServiceProxies.PersonalDevelopmentPlanNotificationTaskServiceProxy,
        ApiServiceProxies.WebLogServiceProxy,
        ApiServiceProxies.DealershipServiceProxy,
        ApiServiceProxies.VehicleBrandServiceProxy,
        ApiServiceProxies.ConnectGivexCardServiceProxy,
        ApiServiceProxies.HugUserServiceProxy,
        ApiServiceProxies.DonateServiceProxy,
        ApiServiceProxies.CharityServiceProxy,
        ApiServiceProxies.AffiliationDetailServiceProxy,
        ApiServiceProxies.AffiliationGroupServiceProxy,
        ApiServiceProxies.ReferralProgramServiceProxy,
        ApiServiceProxies.AccountServiceProxy,
        ApiServiceProxies.TokenAuthServiceProxy,
        ApiServiceProxies.TenantRegistrationServiceProxy,
        ApiServiceProxies.HostDashboardServiceProxy,
        ApiServiceProxies.PaymentServiceProxy,
        ApiServiceProxies.DemoUiComponentsServiceProxy,
        ApiServiceProxies.RecurringJobServiceProxy,
        ApiServiceProxies.InvoiceServiceProxy,
        ApiServiceProxies.SubscriptionServiceProxy,
        ApiServiceProxies.InstallServiceProxy,
        ApiServiceProxies.UiCustomizationSettingsServiceProxy,
        ApiServiceProxies.NewhireCandidateServiceProxy, //Sanitha - added as part of new hire tracking 
        ApiServiceProxies.WebhookEventServiceProxy,
        ApiServiceProxies.WebhookSubscriptionServiceProxy,
        ApiServiceProxies.WebhookSendAttemptServiceProxy,
        ApiServiceProxies.Covid19ScreeningServiceProxy,
        ApiServiceProxies.CovidDocumentServiceProxy,
        { provide: RefreshTokenService, useClass: ZeroRefreshTokenService },
        { provide: HTTP_INTERCEPTORS, useClass: AbpHttpInterceptor, multi: true }
    ]
})
export class ServiceProxyModule { }
