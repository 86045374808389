import { NgModule } from '@angular/core';
import { AppBsModalDirective } from './app-bs-modal.directive';

@NgModule({
    declarations: [AppBsModalDirective],
    exports: [AppBsModalDirective]
})
export class AppBsModalModule {

}
