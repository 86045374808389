import { Component, Injector, ViewEncapsulation, Input } from '@angular/core';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import * as _ from 'lodash';
import { AppComponentBase } from 'shared/common/app-component-base';
import { ICheckOptionDictionary } from './../check-option.model';

@Component({
    selector: 'multi-check-box',
    templateUrl: './multi-check-box.component.html',
    encapsulation: ViewEncapsulation.None,
    animations: [appModuleAnimation()]
})
export class MultiCheckBoxComponent extends AppComponentBase {
    @Input() checkOptionDictionary: ICheckOptionDictionary = {};

    constructor(
        injector: Injector,
    ) {
        super(injector);
    }
    keys(): Array<string> {
        return Object.keys(this.checkOptionDictionary);
    }
}
