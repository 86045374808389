export interface IOption {
    name: string | undefined;
    value: string | undefined;
}

export class Option implements IOption {
    public name: string;
    public value: string;

    constructor(name?, value?) {
        this.name = name;
        this.value = value;
    }

    init(data?: any) {
        if (data) {
            this.name = data["name"];
            this.value = data["value"];
        }
    }
    static fromJS(data: any): Option {
        let result = new Option();
        result.init(data);
        return result;
    }
    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["name"] = this.name;
        data["value"] = this.value;
        return data;
    }
}
