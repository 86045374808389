import { Injectable } from '@angular/core';
import { CharityServiceProxy } from '@shared/service-proxies/service-proxies';
import { OptionService } from './option.service';

@Injectable()
export class CharityService {
    constructor(
        private _charityService: CharityServiceProxy,
        private _optionService: OptionService,
    ) {
    }

    getOptions() {
        return new Promise(resolve => {
            this._charityService.getCharityNames().subscribe(result => {
                let charityNames = result;
                resolve(this._optionService.converStringListToOptions(charityNames));
            });
        });
    }
}
