import { Component, Injector, ViewEncapsulation, OnInit, AfterViewInit } from '@angular/core';
import { AppMenu } from './app-menu';
import { Router } from '@angular/router';

import { PermissionCheckerService } from '@abp/auth/permission-checker.service';
import { AppComponentBase } from '@shared/common/app-component-base';
import { AppSessionService } from '@shared/common/session/app-session.service';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { AppUiCustomizationService } from '@shared/common/ui/app-ui-customization.service';
import { AppNavigationService } from './app-navigation.service';
import { WseComponentBase } from "@app/shared/common/wse-common.base.component";
import { ClarityEmployeeServiceProxy, ConnectMenuServiceProxy, ConnectMenuListDto, CovidDocumentServiceProxy } from '@shared/service-proxies/service-proxies';

@Component({
    templateUrl: './wse-side-bar-menu.component.html',
    styleUrls: ['./wse-side-bar-menu.component.less'],
    selector: 'wse-side-bar-menu',
    encapsulation: ViewEncapsulation.None,
    animations: [appModuleAnimation()]

})
export class WSESideBarMenuComponent extends WseComponentBase implements OnInit, AfterViewInit{

    menu: AppMenu = null;
    availableVacationPayBalance: number = 0;
    connectMenus: ConnectMenuListDto[] = [];
    noVaccineReceipt: boolean = false;

    constructor(
        injector: Injector,
        public permission: PermissionCheckerService,
        private _appSessionService: AppSessionService,
        private _uiCustomizationService: AppUiCustomizationService,
        private _appNavigationService: AppNavigationService,
        private _router: Router,
        private employeeClarityService: ClarityEmployeeServiceProxy,
        private connectMenuService: ConnectMenuServiceProxy,
        private _coviddocumentService: CovidDocumentServiceProxy    ) {
        super(injector);
    }

    ngOnInit(): void {
        let self = this;
        if (this.userName != "John Esplen" && this.userName != 'admin admin' && !this.permission.isGranted('Pages.NewHires')) {
            this.employeeClarityService.getVacationPayBalance().subscribe(result => {
                self.availableVacationPayBalance = result;
            });

            this.connectMenuService.getConnectMenus("", "", 100, 0).subscribe(result => {
                this.connectMenus = result.items.filter(item => item.name != 'Home' && item.isVisible);
                this.connectMenus = this.connectMenus.sort();
            });

            //this._coviddocumentService.getLastCovidDocumentForCurrentLoggedInUser().subscribe(result => {
            //    if (result == null || (!result.isValidDoseReciept && !result.isValidVaccinationReciept)) 
            //        self.noVaccineReceipt = true;
            //})
        }
    }

    ngAfterViewInit(): void {
        Offcanvas.create();
    }

    routerLink($event): void {
        if ($event == "vacation-pay") {
            $event = 'wseportal-request';
            localStorage.setItem('work-schedule-exception-reason-group', 'Vacation pay only');
        } else {
            localStorage.setItem('work-schedule-exception-reason-group', '');
        }
        this._router.navigate([`app/main/${$event}`]);
    }


    connectRouterLink($event): void {
        localStorage.setItem("connectRouterLink", $event);
        this._router.navigate([`app/main/${$event}`]);
        this.routerLink('content');
    }

    navigateToContents(): void {
        localStorage.removeItem('isConnectViewRequired');
        window.location.href = 'app/main/connect-posts';
    }

    hasDoneCovid19ScreeningToday(): boolean {
        return this.appSession.user.hasCovid19ScreeningDoneToday;
    }

    doCovid19Screening(): void {
        localStorage.setItem('doCovid19Screening','true');
        this.connectRouterLink('home');
    }

    uploadVaccineReceipt(): void {
        sessionStorage.removeItem("covidModalLoaded");
        this.connectRouterLink('home');
    }
}
